import "../styles/Button.css";
import React, {ReactNode} from "react";

export enum ButtonType {
    CallToAction = "btn-cta",
    CallToActionLight = "btn-cta-light",
    Link = "btn-link",
    Primary = "btn-primary"
}
export enum ButtonSize {
    Large = "btn-lg",
    Medium = "btn-md",
    Small = "btn-sm"
}

export enum ButtonIcon {
    Coffee = 1,
    Figma = 2
}

interface ButtonProps {
    type        : ButtonType,
    onClick     : string | (() => void);
    icon?       : ButtonIcon,
    classes?    : string[],
    children?   : ReactNode;
}

export const Button : React.FC<ButtonProps> = (props) => {
    function renderIcon() {
        switch (props.icon) {
            case ButtonIcon.Coffee:
                return IconCoffee();
            case ButtonIcon.Figma:
                return IconFigma();
            default:
                return undefined;
        }
    }
    const icon = renderIcon();
    if((props.type === ButtonType.Link &&  typeof props.onClick === "string") || typeof props.onClick === "string") {
        return <a className={"button " + props.type + ((props.classes && props.classes.length > 0) ? " " + props.classes.join(" ") : "" )} href={props.onClick} target="_blank">
            {icon}
            {icon ? <span>{props.children}</span> : props.children}
        </a>
    } else {
        return <button className={"button " + props.type + ((props.classes && props.classes.length > 0) ? " " + props.classes.join(" ") : "" )} onClick={props.onClick}>
            {icon}
            {icon ? <span>{props.children}</span> : props.children}
        </button>
    }
}

function IconCoffee() {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.91666 7.71374H12.9167V14.3768C12.9167 14.8143 12.8304 15.2475 12.6629 15.6518C12.4954 16.0559 12.2499 16.4232 11.9403 16.7326C11.6308 17.0419 11.2633 17.2873 10.8589 17.4548C10.4545 17.6222 10.0211 17.7083 9.58333 17.7083H6.25C5.36594 17.7083 4.5181 17.3573 3.89297 16.7326C3.26786 16.1078 2.91666 15.2604 2.91666 14.3768V7.71374Z" stroke="#8000FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.9167 8.54659H15.4167C15.8587 8.54659 16.2826 8.72209 16.5952 9.03451C16.9077 9.34692 17.0833 9.77059 17.0833 10.2124V13.5439C17.0833 13.9857 16.9077 14.4094 16.5952 14.7218C16.2826 15.0342 15.8587 15.2097 15.4167 15.2097H12.8083" stroke="#8000FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M4.90833 5.6232C5.01818 5.51406 5.10537 5.38429 5.16486 5.24135C5.22434 5.09841 5.25497 4.94513 5.25497 4.79032C5.25497 4.63551 5.22434 4.48223 5.16486 4.33928C5.10537 4.19634 5.01818 4.06658 4.90833 3.95743C4.79847 3.84829 4.7113 3.71853 4.65181 3.57558C4.59232 3.43264 4.56169 3.27936 4.56169 3.12455C4.56169 2.96974 4.59232 2.81646 4.65181 2.67352C4.7113 2.53058 4.79847 2.40081 4.90833 2.29167" stroke="#8000FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7.61667 5.6232C7.83651 5.40168 7.95986 5.10232 7.95986 4.79032C7.95986 4.47832 7.83651 4.17895 7.61667 3.95743C7.50681 3.84829 7.41964 3.71853 7.36014 3.57558C7.30065 3.43264 7.27003 3.27936 7.27003 3.12455C7.27003 2.96974 7.30065 2.81646 7.36014 2.67352C7.41964 2.53058 7.50681 2.40081 7.61667 2.29167" stroke="#8000FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M10.525 5.6232C10.6348 5.51406 10.722 5.38429 10.7815 5.24135C10.841 5.09841 10.8717 4.94513 10.8717 4.79032C10.8717 4.63551 10.841 4.48223 10.7815 4.33928C10.722 4.19634 10.6348 4.06658 10.525 3.95743C10.4152 3.84829 10.328 3.71853 10.2685 3.57558C10.209 3.43264 10.1783 3.27936 10.1783 3.12455C10.1783 2.96974 10.209 2.81646 10.2685 2.67352C10.328 2.53058 10.4152 2.40081 10.525 2.29167" stroke="#8000FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>;
}
function IconFigma() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="none">
          <g clipPath="url(#clip0_1278_6881)">
            <path d="M1.83333 11.5C2.84533 11.5 3.66667 10.6787 3.66667 9.66671V7.83337H1.83333C0.821333 7.83337 0 8.65471 0 9.66671C0 10.6787 0.821333 11.5 1.83333 11.5Z" fill="#0ACF83"/>
            <path d="M0 5.99996C0 4.98796 0.821333 4.16663 1.83333 4.16663H3.66667V7.83329H1.83333C0.821333 7.83329 0 7.01196 0 5.99996Z" fill="#A259FF"/>
            <path d="M0 2.33333C0 1.32133 0.821333 0.5 1.83333 0.5H3.66667V4.16667H1.83333C0.821333 4.16667 0 3.34533 0 2.33333Z" fill="#F24E1E"/>
            <path d="M3.6665 0.5H5.49984C6.51184 0.5 7.33317 1.32133 7.33317 2.33333C7.33317 3.34533 6.51184 4.16667 5.49984 4.16667H3.6665V0.5Z" fill="#FF7262"/>
            <path d="M7.33317 5.99996C7.33317 7.01196 6.51184 7.83329 5.49984 7.83329C4.48784 7.83329 3.6665 7.01196 3.6665 5.99996C3.6665 4.98796 4.48784 4.16663 5.49984 4.16663C6.51184 4.16663 7.33317 4.98796 7.33317 5.99996Z" fill="#1ABCFE"/>
            </g>
        <defs>
            <clipPath id="clip0_1278_6881">
                <rect width="7.33333" height="11" fill="white" transform="translate(0 0.5)"/>
            </clipPath>
        </defs>
    </svg>;
}
